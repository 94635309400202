html {
  font-family: sans-serif;
  text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block; }

audio:not([controls]) {
  display: none;
  height: 0; }

progress {
  vertical-align: baseline; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline-width: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: inherit; }

b,
strong {
  font-weight: bolder; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

mark {
  background-color: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

img {
  border-style: none; }

svg:not(:root) {
  overflow: hidden; }

code,
kbd,
pre,
samp {
  /* stylelint-disable */
  font-family: monospace, monospace;
  /* stylelint-enable */
  font-size: 1em; }

figure {
  margin: 1em 40px; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

button,
input,
select,
textarea {
  font: inherit; }

optgroup {
  font-weight: bold; }

button,
input,
select {
  overflow: visible; }

button,
input,
select,
textarea {
  margin: 0; }

button,
select {
  text-transform: none; }

[type="button"],
[type="reset"],
[type="submit"],
button {
  cursor: pointer; }

[disabled] {
  cursor: default; }

[type="reset"],
[type="submit"],
button,
html [type="button"] {
  appearance: button; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

button:-moz-focusring,
input:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal; }

textarea {
  overflow: auto; }

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  appearance: field; }

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  appearance: none; }

/*$navigation__background: $white;
$header-basket-badge: $lemon-ginger;
$header-input-placeholder: $river-bad;
$header-top-links: $river-bad;
$header-top-hover: $lemon-ginger;
$header-bottom-links: $alto;
$header-bottom-hover: $lemon-ginger;
$nav-backgr: $nomad;
$nav-backgr-hover: $schooner;
$nav-border: $dusty-gray;
$nav-sub-links: $elephant;
$nav-sub-links-cover: $lemon-ginger;
$breadcrumb-links: $river-bad;
$footer-backgr: $river-bad;
$footer-heading: $limed-spruce;
$footer-links-hover: $lemon-ginger;
$footer-bottom: $eastern-blue;
$button-dashed-square: $lemon-ginger;
$info-dashed-round: $stonewall;
$heading-text: $river-bad;
$heading-transparent: $nomad;
$product-title: $river-bad;
$product-title-hover: $lemon-ginger;
$product-price: $donkey-brown;
$select-outer-border: $silver;
$select-inner-border: $alto;
$select-text: $river-bad;
$select-button: $lemon-ginger;
$inputs-border: $alto;
$inputs-text: $tundora;
$product-details-width: $river-bad;
$product-details-price-old: $lemon-ginger;
$product-details-price-new: $tundora;
$product-details-length: $tundora;
$product-details-links: $dusty-gray;
$product-details-links-hover: $lemon-ginger;
$product-details-promo: $eastern-blue;
$product-details-share: $alto;
$a-z-fabrics-text: $elephant;
$border-default: $dusty-gray;
*/
/*
$forms__border: 2px solid $dusty-gray2;
$forms__height: 5.6rem !important;
$forms__margin: .5rem 0 .5rem 0;
$forms__padding: 0 0 0 1rem;
$forms__valign: middle; 
$forms__width: 100%;

$form-field-type-revert: block;
$form-field-type-label-inline__width: 0;

$input-text__border: $forms__border;
$input-text__height: $forms__height;
$input-text__line-height: $forms__height;
$input-text__margin: $forms__margin;
$input-text__padding: $forms__padding;
$input-text__vertical-align: $forms__valign;
$input-text__width: $forms__width;

$select__border: $forms__border;
$select__height: $forms__height;
$select__line-height: $forms__height;
$select__margin: $forms__margin;
$select__padding: $forms__padding;
$select__vertical-align: $forms__valign;
$select__width: $forms__width;

$textarea__border: $forms__border;
$textarea__height: false;
//$textarea__line-height: $forms__height;
$textarea__margin: $forms__margin;
$textarea__padding: $forms__padding;
$textarea__vertical-align: $forms__valign;
$textarea__width: $forms__width;

$form-field-type: block;
$form-field-label__font-weight: normal;
$form-field-type-label-block__align: left;
$form-field-label-asterisk__color: inherit;

//$footer__background-color: ;

$button__min-width: 0;
$button__border-radius: 0;
$button__border: 0;
$button__color: $white;
$button__background: $azure-two;
$button__hover__border: 0;
$button__hover__color: $white;
$button__hover__background: $light-navy-blue;;
$button__active__color: $white;
$button__active__background: $light-navy-blue;
$button-primary__border: 0;
$button-primary__color: $white;
$button-primary__background: $azure-two;
$button-primary__hover__border: 0;
$button-primary__hover__color: $white;
$button-primary__hover__background: $light-navy-blue;
$button-primary__active__color: $white;
$button-primary__active__background: $light-navy-blue;

$link__color: $charcoal-grey;
$link__text-decoration: none;
$link__visited__color: darken($link__color, 20%);
$link__visited__text-decoration: none;
$link__hover__color: darken($link__color, 50%);
$link__hover__text-decoration: none;
$link__active__color: darken($link__color, 20%);
$link__active__text-decoration: none;

$autocomplete__border: 0;
$autocomplete-item__border: 0;


//$submenu__background: rgba(255, 255, 255, 0.5);
$submenu-item__active__border: 0;
$submenu__font-weight: 500;

$modal-title__border: 0;

$breadcrumbs__container-margin: 2rem 0;

$pager__color: $cool-grey;
$pager__line-height: 1em;
$pager-item__padding: 1em;
$pager-current__background: $white-three;
*/
/*
$product-item-sku__font-size: 1em;
$product-item-sku__color: $cool-grey-two;
$product-item-name__font-size: 1.15em;
$product-item-name__color: $charcoal-grey;
$product-item-price__font-size: 3rem;
$product-item-price__color: $light-navy-blue;
$product-item-stock__font-size: 0.9em;
$product-item-stock__color: $cool-grey-two;
*/
.page-print .logo {
  float: none;
  display: block;
  text-align: left; }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    text-shadow: none !important;
    -webkit-filter: none !important;
    filter: none !important; }
  a,
  a:visited {
    text-decoration: underline !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  .table-wrapper table {
    width: 100%;
    table-layout: fixed; }
    .table-wrapper table td {
      width: auto; }
    .table-wrapper table > tfoot > tr:first-child {
      border-top: 1px solid #999; }
  .box,
  tr,
  img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  @page {
    margin: 1cm; }
  .block-content,
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  .block-content {
    page-break-before: avoid; }
  .block-title,
  h2,
  h3 {
    page-break-after: avoid; }
  .nav-toggle {
    display: none !important; }
  .sidebar,
  .nav-sections,
  .header.content > *[class],
  .panel.wrapper > *[class],
  .footer.content > *[class] {
    display: none; }
  .logo,
  .footer .copyright {
    display: block !important;
    margin: 10px 0; }
  .order-details-items .order-items .order-gift-message:not(.expanded-content) {
    visibility: visible;
    height: auto; }
  .column.main {
    width: 100% !important;
    float: none !important; }
  .breadcrumbs {
    margin: 0 auto;
    text-align: right; }
  .footer.content {
    padding: 0; } }
